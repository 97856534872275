import React from 'react'
import * as Url from "../../variables/url.jsx";
 
class Map extends React.Component{
  constructor(props){
    super(props);
    this.platform=null;
    this.map=null;
    this.state={
        app_id:"YE8naT93k3v81vSIxEt4",
        app_code:"eE-vFx8f2UVWqgMMGqixDQ",
        center:{
          lat:43.831739,
          lng: 17.801319},
        zoom:8,
        theme:"normal.day",       
        useHTTPS: true,
        allCamerasNew:[]
       }
    }
    //TODO:AddthemeselectiondiscussedlaterHERE
    componentDidMount(){
      this.populateCameras();//eslint-disable-next-line
     
// Add info bubble to the UI:    
     
    }
    populateCameras() {   
    
    fetch(Url.ApiUrl+"events/getEventsByCategoryCameraMap")
    .then(res=>res.json())
    .then(result=>{
    
      this.setState({allCamerasNew:result});
      console.log(this.state.allCamerasNew);
      this.platform=new window.window.H.service.Platform(this.state);
    
      var layer=this.platform.createDefaultLayers();
      var container=document.getElementById('here-map');
      var mapInit=new window.window.H.Map(container,layer.normal.map,{center:this.state.center,zoom:this.state.zoom,theme:"normal.day.grey"});
      var ui=new window.window.H.ui.UI.createDefault(mapInit,layer)    
      
      this.map=mapInit;
      var events=new window.window.H.mapevents.MapEvents(this.map);
      //eslint-disable-next-line
      var behavior=new window.window.H.mapevents.Behavior(events);
      this.addInfoBubble(this.map,ui);
    });

    }
    addMarkerToGroup(group, lat,lng, html,logo) {
      var icon = new window.window.H.map.Icon('images/home.png');
      var coordinate={
        lat:Number(lat),
        lng:Number(lng)
      };
      console.log(coordinate);
      var marker = new window.H.map.Marker(coordinate,{ icon: icon });

      // add custom data to the marker
      marker.setData(html);
     
      console.log(marker);
      group.addObject(marker);
      console.log(group);
    }
    addInfoBubble(map,ui) {
      var group = new window.H.map.Group();
    
      map.addObject(group);
    
      // add 'tap' event listener, that opens info bubble, to the group
      group.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var bubble =  new window.H.ui.InfoBubble(evt.target.getGeometry(), {
          // read custom data
          content: evt.target.getData()
        });
        // show info bubble
        ui.addBubble(bubble);
      }, false);
      
      this.state.allCamerasNew.map(element => {
        
          //if(element.id < 10){
            this.addMarkerToGroup(group, element.lat,element.lng, '<img src="'+element.url_image+'" style="width:400px" /> <br/>'+element.name+'<br/>',element.source);            
              
         // }
          
       });
    
    
    }
      
      render(){return(
        <div id="here-map" style={{width:'100%',height:'100%',background:"grey"}}/>);}}

export default Map;