import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import * as cameras from "../../variables/allCamera.jsx";
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import * as Url from "../../variables/url";

class Camera extends Component {
  constructor(props){
    super(props);
    this.state={      
      camera:null,
      visible:false,
      fetch:false
      //cameraImage:""      
    }
    this.handleClick=this.handleClick.bind(this);
    console.log(this.props.match.params.number);
   
  }
  componentDidMount() {  
    
    this.intervalID = setInterval(
      () => this.tick(),
      30000
    );
  }
  tick() {
       
    //this.props.history.push("/kamere");         
    window.location.reload();
  }
  componentWillMount(){
    this.populateCameras();
  }
  populateCameras() {   
    
   
    fetch(Url.ApiUrl+"events/getEventsShow/"+this.props.match.params.number)
    .then(res=>res.json())
    .then(result=>{
      console.log(result);
      this.setState({camera:result,fetch:true});
    });
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.number !== prevProps.match.params.number) {
      this.populateCameras();
    }
  }
  handleClick(event){
    event.preventDefault()
    //this.setState({visible:true});
    //document.oncontextmenu = new Function("return false")
  }
 
  render() {
    return (
      <div className="content">
        <Grid fluid>
       {this.state.fetch ?
         
          <Row>
            <Col mdOffset={2} lgOffset={2} md={8} lg={8} sm={12}>
              <Card
                isVisble={this.state.visible}
                title={this.state.camera.name}            
                icon={"logo_amsbih"}
                source={this.state.camera.source}
                expand={""
                  //<span className="pull-right expand" alt="kamera" onClick={this.handleClick}><i className="pe-7s-expand1"></i></span>
                }
                 content={
                   [//<a href={"#/kamera/"+this.state.camera.id}>
                      <div className="content-image">
                        <img src={this.state.camera.url_image} style={{width:'100%'}}  alt={this.state.camera.name} onClick={this.handleClick} oncontextmenu={"return false"}/>
                    </div>
                   //</a>
                   
                 ]
                 }
              />
            </Col>
          
          </Row>   
          :null}     
          
        </Grid>
      </div>
    );
  }
}
export default Camera;

