import Dashboard from "../views/Dashboard/Dashboard";
import Camera from "../views/Camera/Camera";
import MapHere from "../views/Maps/MapHere.jsx";
import Category from "../views/Category/Category";
import Granica from "../views/Category/Granica";
const dashboardRoutes = [
  {
    path: "/kamere",
    name: "Sve kamere",
    icon: "pe-7s-display1",
    component: Dashboard
  },
  {
    path: "/kamera/:number",  
    icon: "pe-7s-camera",
    component: Camera
  },
  {
    path: "/karta",  
    icon: "pe-7s-map",
    component: MapHere
  },
  {
    path: "/heremap",  
    icon: "pe-7s-map",
    component: MapHere
  },
  {
    path: "/kategorija/:number",  
    icon: "pe-7s-camera",
    component: Category
  },
  {
    path: "/granica/:number",  
    icon: "pe-7s-camera",
    component: Granica
  },
  
  { redirect: true, path: "/", to: "/kamere", name: "Sve kamere" }
];

export default dashboardRoutes;
