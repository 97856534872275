import Dashboard from "../views/Dashboard/Dashboard";
import Category from "../views/Category/Category";
import Granica from "../views/Category/Granica";
import Maps from "../views/Maps/MapHere"
const dashboardRoutes = [

  {
    path: "/kamere",
    name: "Sve kamere",
    icon: "pe-7s-monitor",
    component: Dashboard,
    image:"traffic"
  },
  {
    path: "/karta",  
    name: "Karta kamera",
    icon: "pe-7s-map",
    component: Maps,
    image:"traffic"
  },
  {
    path: "/kategorija/15",
    name: "Magistralne ceste",
    icon: "pe-7s-camera",
    component: Category,
    image:"logo_amsbih"
  },
  {
    collapse:true,
    path: "/home",
    name: "Granični prijelazi",
    icon: "pe-7s-camera",
    state: "openForms",
    image:"trafficLogo3",
    views:[
      {
        path: "/granica/27",
        name: "BIH-HRV",
        icon: "pe-7s-ss",
        component: Granica,
        image:"trafficLogo3"
      },
      {
        path: "/granica/16",
        name: "BIH-SRB",
        icon: "pe-7s-ss",
        component: Granica,
        image:"trafficLogo3"
      },
      {
        path: "/granica/28",
        name: "BIH-CG",
        icon: "pe-7s-ss",
        component: Granica,
        image:"trafficLogo3"
      },
    ]
  },

  
  /*
  {
    path: "/kamera/1",
    name: "Borova glava",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  {
    path: "/kamera/2",
    name: "Kupreška vrata",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  {
    path: "/kamera/3",
    name: "Jajce",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  
  {
    path: "/kamera/4",
    name: "GP Gorica",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  
  {
    path: "/kamera/5",
    name: "Mesihovina",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  
  {
    path: "/kamera/6",
    name: "Donji malovan",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  
  {
    path: "/kamera/7",
    name: "Drvar - Podovi",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  
  {
    path: "/kamera/8",
    name: "Kamera 8",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  {
    path: "/kamera/9",
    name: "Kamera 9",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },
  {
    path: "/kamera/10",
    name: "Kamera 10",
    icon: "pe-7s-camera",
    component: Camera,
    image:"trafficLogo"
  },*/
  { redirect: true, path: "/", to: "/granica/1", name: "Sve kamere" }
];

export default dashboardRoutes;
