import React, { Component } from "react";

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.isVisible ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    alert("ssss")
    this.setState({ visible: true });
  }
  render() {
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "")}>
        
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >
          {this.props.content}
        
          <div className="footer">
         
          {this.props.source==="home" ?
             <img className="icon-footer" src={"images/1288.png"} alt="kamera"/>
             :null}
         
            {this.props.legend}
            {this.props.stats != null ? [<hr /> ,
            <div className="stats">
            <i className={this.props.statsIcon} /> {this.props.stats}
            
          
            
          </div>]
            : ""}
            
          </div>
        </div>
        <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
          <h4 className="title"> {this.props.title}</h4>
          {this.props.expand}
          <p className="category">{this.props.category}</p>
        </div>
      </div>
    );
  }
}

export default Card;

/**
*/
