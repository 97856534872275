import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import * as cameras from "../../variables/allCamera.jsx";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";
import * as Url from "../../variables/url";
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCameras: [],
      allCamerasInit: [],
      datum: new Date().toLocaleString(),
      visible: false,
      currentImage: "",
      isVisible: false,
      //cameraImage:""
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenFilter = this.handleOpenFilter.bind(this);
    this.searchList = this.searchList.bind(this);
    //alert(this.props.match.params.number);
  }
  componentWillMount() {
    this.intervalID = setInterval(() => this.tick(), 30000);
    this.populateCameras();
    // console.log(this.state.allCameras);
  }
  populateCameras() {
    this.setState({ allCameras: [] });
    fetch(
      Url.ApiUrl +
        "events/getEventsByPriority/" +
        this.props.match.params.number
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({ allCameras: result, allCamerasInit: result });
      });
  }
  // console.log(this.state.allCameras);
  tick() {
    this.setState({
      //allCameras:[],
      datum: new Date().toLocaleString(),
    });

    //this.props.history.push("/kamere");
    window.location.reload();
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.number !== prevProps.match.params.number) {
      this.populateCameras();
    }
  }

  handleClick(event) {
    event.preventDefault();
    //console.log(event.target.id);
    this.setState({ visible: true, currentImage: event.target.id - 1 });
  }
  handleClickBack() {
    window.location.href = "#/karta";
  }
  handleOpenFilter() {
    //console.log("Open");
    this.setState({ isVisible: !this.state.isVisible });
  }
  searchList(event) {
    let text = event.target.value;
    //if(text.length)
    // {
    let filteredData = this.state.allCamerasInit;
    filteredData = filteredData.filter((client) => {
      let val = client.name.toLowerCase();
      return val.indexOf(text.toLowerCase()) !== -1;
    });

    this.setState({ allCameras: filteredData });
  }
  handleClickCam(event) {
    console.log(event.target.id);
    window.location.href = "#/kamera/" + event.target.id;
  }
  render() {
    return (
      <div className="content">
        <div className="filter" onClick={this.handleOpenFilter}>
          <i className="pe-7s-filter"></i>
          {this.state.datum}
        </div>

        <Grid fluid>
          <Viewer
            visible={false}
            onClose={() => {
              this.setState({ visible: false });
            }}
            images={cameras.ImageNames}
            activeIndex={this.state.currentImage}
          />
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <input
                type="text"
                name="search"
                onChange={this.searchList}
                className="form-control"
                placeholder="Pretraži...."
              />
              <br />
            </Col>
          </Row>

          <Row>
            <div className="clearfix"></div>
            {this.state.allCameras.map((item) => (
              <Col lg={3} md={6} sm={6} xs={12}>
                <Card
                  key={item.id}
                  title={item.name}
                  icon={"logo_amsbih"}
                  expand={
                    ""
                    //<span className="pull-right expand" alt="kamera" onClick={this.handleClick}><i id={item.id} className="pe-7s-expand1"></i></span>
                  }
                  source={item.source}
                  content={[
                    <a onClick={this.handleClickCam} id={item.id}>
                      <div className="content-image">
                        {item.source == "home" ? (
                          <img
                            src={item.url_thumbnail}
                            style={{ width: "100%" }}
                            alt={item.name}
                            onClick={this.handleClick}
                            id={item.id}
                          />
                        ) : null}
                        {item.source == "hak" ? (
                          <img
                            src={item.url_thumbnail}
                            style={{ width: "75%" }}
                            alt={item.name}
                            onClick={this.handleClick}
                            id={item.id}
                          />
                        ) : null}
                        {item.source == "bihamk" ? (
                          <img
                            src={item.url_thumbnail}
                            style={{ width: "69%" }}
                            alt={item.name}
                            onClick={this.handleClick}
                            id={item.id}
                          />
                        ) : null}
                        {item.source == "rs" ? (
                          <img
                            src={item.url_thumbnail}
                            style={{ width: "100%" }}
                            alt={item.name}
                            onClick={this.handleClick}
                            id={item.id}
                          />
                        ) : null}
                      </div>
                    </a>,
                  ]}
                />
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Category;
