export const Camera2=[    
    {
        id:1,
        CameraName:"Borova glava",
        CameraDescription:"Borova glava",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_1/BorovaGlava.jpg",
        coordinate:{
            lat:43.775249,
            lng:  17.115089
          },
          CameraType:"maper-icon2",
        Type:1
        
    },
    {
        id:2,
        CameraName:"Kupreška vrata",
        CameraDescription:"Kupreška vrata",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_2/KupreskaVrata.jpg",
        coordinate:{
            lat:44.016033,
            lng:17.287498
          },
        CameraType:"maper-icon2",
        Type:1
    
    },
    {
        id:3,
        CameraName:"Jajce",
        CameraDescription:"Autobusni kolodvor Jajce",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_3/Jajce.jpg",
        coordinate:{
            lat:44.337165,
            lng: 17.267722
        },
        CameraType:"maper-iconISP3",
        Type:3
    },
    {
        id:4,
        CameraName:"GP Gorica",
        CameraDescription:"Granični prijelaz Gorica Grude",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_4/0Gorica.jpg",
        coordinate:{
            lat:43.421988,
            lng: 17.275612
        },
        CameraType:"maper-icon2",
        Type:2
    },
    {
        id:5,
        CameraName:"Mesihovina",
        CameraDescription:"Mesihovina",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_5/0Mesihovina.jpg",
        coordinate:{
            lat:43.586832,
            lng: 17.281050
        },
        CameraType:"maper-icon2",
        Type:1

    },
    {
        id:6,
        CameraName:"Donji Malovan",
        CameraDescription:"Donji Malovan",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_6/Donji_Malovan.jpg",
        coordinate:{
            lat:43.900032,
            lng:17.192225
        },
        CameraType:"maper-iconRadar2",
        Type:1
    },
    {
        id:7,
        CameraName:"Drvar - Podovi",
        CameraDescription:"Drvar - Podovi",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_7/Drvar.jpg",
        coordinate:{
            lat:44.416883,
            lng:16.397933
        },
        CameraType:"maper-iconUcb",
        Type:1
    },
    {
        id:8,
        CameraName:"GP Gabela Polje",
        CameraDescription:"Granični prijelaz Gabela Polje",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_8/GabelaPolje.jpg",
        coordinate:{
            lat:43.832892, 
            lng: 18.308716
        },
        CameraType:"maper-iconRaiff2",
        Type:2

    },
    {
        id:9,
        CameraName:"Kamera 9",
        CameraDescription:"d.d.d",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_9/lokacija_9.jpg",
        coordinate:{
            lat:43.068714,
            lng: 17.6628066
        },
        CameraType:"carina",
        Type:3

    },
    {
        id:10,
        CameraName:"Kamera 10",
        CameraDescription:"d.d.d",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_10/lokacija_10.jpg",
        coordinate:{
            lat:43.832892, 
            lng: 18.308716
        },
        CameraType:"maper-icon2",
        Type:1

    }
];

export const Camera=[    
    {
        id:1,
        CameraName:"M-15 Livno - Šujica",
        CameraDescription:"Borova glava",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_1/BorovaGlava.jpg",
        coordinate:{
            lat:43.775249,
            lng:  17.115089
          },
          CameraType:"home",
          Type:1,
          Source:1,
          Path:0
        
    },
    {
        id:2,
        CameraName:"M-16 Kupres - Bugojno",
        CameraDescription:"Kupreška vrata",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_2/KupreskaVrata.jpg",
        coordinate:{
            lat:44.016033,
            lng:17.287498
          },
          CameraType:"home",
          Type:1,
          Source:1,
          Path:0
        
    },
    {
        id:3,
        CameraName:"M-5 Jajce",
        CameraDescription:"Autobusni kolodvor Jajce",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_3/Jajce.jpg",
        coordinate:{
            lat:44.337165,
            lng: 17.267722
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0
    },    
    {
        id:4,
        CameraName:"M-6.1 Posušje - Tomislavgrad",
        CameraDescription:"Mesihovina",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_5/0Mesihovina.jpg",
        coordinate:{
            lat:43.586832,
            lng: 17.281050
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },
    {
        id:5,
        CameraName:"M-16 Šujica - Kupres",
        CameraDescription:"Donji Malovan",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_6/Donji_Malovan.jpg",
        coordinate:{
            lat:43.900032,
            lng:17.192225
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0
    },
    {
        id:6,
        CameraName:"M-14.2 Drvar - B.Petrovac",
        CameraDescription:"Drvar - Podovi",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_7/Drvar.jpg",
        coordinate:{
            lat:44.416883,
            lng:16.397933
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0
    },
    {
        id:7,
        CameraName:"M-5 Vitez",
        CameraDescription:"Vitez",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_11/Vitez.jpg",
        coordinate:{
            lat:44.151243, 
            lng: 17.805145
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },
    {
        id:8,
        CameraName:"M-17 Jablanica(motel Maksumić)",
        CameraDescription:"Jablanica(motel Maksumić)",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_12/Jablanica.jpg",
        coordinate:{
            lat:43.664126,
            lng:  17.768956
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },
    {
        id:9,
        CameraName:"M-15 Glamoč-Mliništa",
        CameraDescription:"M-15 Glamoč-Mliništa",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_13/0Glamoc.jpg",
        coordinate:{
            lat:44.0483,
            lng:  16.8436
        },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },    
    {
        id:10,
        CameraName:"GP Ivanica (izlaz)",
        CameraDescription:"GP Ivanica (izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/414.jpg",
        coordinate:{
            lat:42.6590309,
            lng: 18.1609197
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:11,
        CameraName:"GP Neum I (izlaz)",
        CameraDescription:"Granični prijelaz Neum I",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_14/0Neum.jpg",
        coordinate:{
            lat:42.936432,
            lng:  17.582749
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:12,
        CameraName:"GP Neum II (izlaz)",
        CameraDescription:"Granični prijelaz Neum II",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_15/0NeumII.jpg",
        coordinate:{
            lat:42.8897845,
            lng:  17.6493673
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:13,
        CameraName:"GP Klek(izlaz)",
        CameraDescription:"Granični prijelaz Klek",
        CameraImage:"https://www.hak.hr/info/kamere/204.jpg",
        coordinate:{
            lat:42.9378878,
            lng:  17.5808449
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },  
    {
        id:14,
        CameraName:"GP Klek(ulaz)",
        CameraDescription:"Granični prijelaz Klek",
        CameraImage:"https://www.hak.hr/info/kamere/203.jpg",
        coordinate:{
            lat:42.9393337,
            lng:  17.5799578
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:15,
        CameraName:"GP Zaton Doli II(ulaz)",
        CameraDescription:"GP Zaton/Neum II(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/206.jpg",
        coordinate:{
            lat:42.891125,
            lng:  17.6500952
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:16,
        CameraName:"GP Zaton Doli (izlaz)",
        CameraDescription:"Granični prijelaz Klek",
        CameraImage:"https://www.hak.hr/info/kamere/207.jpg",
        coordinate:{
            lat:42.890212,
            lng:17.6511275
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:17,
        CameraName:"GP Metković(izlaz)",
        CameraDescription:"GP Metković(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/321.jpg",
        coordinate:{
            lat:43.0505719,
            lng:  17.6591796
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:18,
        CameraName:"GP Metković(izlaz)",
        CameraDescription:"GP Metković(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/320.jpg",
        coordinate:{
            lat:43.0505719,
            lng:  17.6591796
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:19,
        CameraName:"GP Metković(ulaz)",
        CameraDescription:"GP Metković(ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/319.jpg",
        coordinate:{
            lat:43.0504719,
            lng:  17.6606226
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:20,
        CameraName:"GP Doljani (ulaz)",
        CameraDescription:"GP Doljani (ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/412.jpg",
        coordinate:{
            lat:43.0526012,
            lng:  17.6727262
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:21,
        CameraName:"GP Doljani (izlaz)",
        CameraDescription:"GP Doljani (izlaz)",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/DOLJANI_ULAZ.jpg?",
        coordinate:{
            lat:43.0526012,
            lng:  17.6727262
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:1

    },
    {
        id:22,
        CameraName:"GP Gabela Polje (izlaz)",
        CameraDescription:"Granični prijelaz Gabela Polje",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_8/GabelaPolje.jpg",
        coordinate:{
            lat:43.060018,
            lng: 17.659916
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:23,
        CameraName:"GP Zvirići (izlaz)",
        CameraDescription:"Granični prijelaz Zvirići",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija16/0Zvrirci.jpg",
        coordinate:{
            lat:43.106957,
            lng:  17.6018913
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:24,
        CameraName:"GP Nova Sela(ulaz)",
        CameraDescription:"Granični prijelaz Nova Sela",
        CameraImage:"https://www.hak.hr/info/kamere/201.jpg",
        coordinate:{
            lat:43.1235422,
            lng:  17.559083
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:25,
        CameraName:"GP Nova Sela (izlaz)",
        CameraDescription:"Granični prijelaz Nova Sela",
        CameraImage:"https://www.hak.hr/info/kamere/202.jpg",
        coordinate:{
            lat:43.1235422,
            lng:  17.559083
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:26,
        CameraName:"GP Bijača(izlaz)",
        CameraDescription:"Granični prijelaz Bijača",
        CameraImage:"https://www.hak.hr/info/kamere/411.jpg",
        coordinate:{
            lat:43.1233008,
            lng:  17.5726318
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },      
    {
        id:27,
        CameraName:"GP Crveni Grm (izlaz)",
        CameraDescription:"GP Crveni Grm (ulaz)",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/CRVENIGRM.jpg",
        coordinate:{
            lat:43.1615991,
            lng:  17.4762627
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:1

    },
    {
        id:28,
        CameraName:"GP Orahovlje (izlaz)",
        CameraDescription:"Granični prijelaz Orahovlje",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_9/Orahovlje.jpg",
        coordinate:{
            lat:43.217606, 
            lng: 17.429583
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:29,
        CameraName:"GP Gorica (izlaz)",
        CameraDescription:"Granični prijelaz Gorica",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_4/0Gorica.jpg",
        coordinate:{
            lat:43.421988,
            lng: 17.275612
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1
    },  
    {
        id:30,
        CameraName:"GP Vinjani Donji (izlaz)",
        CameraDescription:"Vinjani Donji (izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/302.jpg",
        coordinate:{
            lat:43.4223623,
            lng: 17.2721276
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:75,
        CameraName:"GP Vinjani Donji (ulaz)",
        CameraDescription:"Vinjani Donji (ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/303.jpg",
        coordinate:{
            lat:43.4223623,
            lng: 17.2721276
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:31,
        CameraName:"GP Osoje (izlaz)",
        CameraDescription:"Granični prijelaz Osoje",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_10/0Osoje.jpg",
        coordinate:{
            lat:43.459595,
            lng: 17.2887372
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    }, 
    {
        id:32,
        CameraName:"GP Aržano (izlaz)",
        CameraDescription:"Aržano (izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/316.jpg",
        coordinate:{
            lat:43.5840768,
            lng:16.9960641
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:33,
        CameraName:"GP Aržano (ulaz)",
        CameraDescription:"Aržano (ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/315.jpg",
        coordinate:{
            lat:43.5840768,
            lng:16.9960641
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:34,
        CameraName:"GP Prisika (izlaz)",
        CameraDescription:"GP Prisika (izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/409.jpg",
        coordinate:{
            lat:43.6065411,
            lng:16.9893137
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:35,
        CameraName:"GP Kamensko (ulaz)",
        CameraDescription:"Granični prijelaz Kamensko",
        CameraImage:"https://www.hak.hr/info/kamere/317.jpg",
        coordinate:{
            lat:43.6113373,
            lng:16.9715581
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:36,
        CameraName:"GP Kamensko (izlaz) ",
        CameraDescription:"Granični prijelaz Kamensko",
        CameraImage:"https://www.hak.hr/info/kamere/318.jpg",
        coordinate:{
            lat:43.6113373,
            lng:16.9715581
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:37,
        CameraName:"GP Kamensko (izlaz)",
        CameraDescription:"Granični prijelaz Kamensko",
        CameraImage:"https://www.hak.hr/info/kamere/408.jpg",
        coordinate:{
            lat:43.6113373,
            lng:16.9715581
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:77,
        CameraName:"GP Strmica (izlaz)",
        CameraDescription:"Granični prijelaz Strmica",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija17/0Strmica.jpg",
        coordinate:{
            lat:44.176132,
            lng:16.263364
        },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:38,
        CameraName:"GP Izačić (izlaz)",
        CameraDescription:"Granični prijelaz Izačić",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/IZACIC.jpg",
        coordinate:{
            lat:44.8750004,
            lng:15.7582059
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:1

    },
    {
        id:39,
        CameraName:"GP Maljevac (ulaz)",
        CameraDescription:"GP Maljevac (ulaz) ",
        CameraImage:"https://www.hak.hr/info/kamere/429.jpg",
        coordinate:{
            lat:45.1976762,
            lng:15.7903089
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:40,
        CameraName:"GP Maljevac (izlaz)",
        CameraDescription:"GP Maljevac (izlaz) ",
        CameraImage:"https://www.hak.hr/info/kamere/430.jpg",
        coordinate:{
            lat:45.1976762,
            lng:15.7903089
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:41,
        CameraName:"GP Velika Kladuša (izlaz)",
        CameraDescription:"Maljevac ('Auto BHS'-BIHAMK)",
        CameraImage:"https://www.hak.hr/info/kamere/406.jpg",
        coordinate:{
            lat:45.1969571,
            lng:15.7874147
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:42,
        CameraName:"GP Novi Grad(ulaz)",
        CameraDescription:"Novi Grad(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_10_GP_NG02/slika.jpg",
        coordinate:{
            lat:45.0492483,
            lng:16.3717171
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:43,
        CameraName:"GP Novi Grad(izlaz)",
        CameraDescription:"Novi Grad(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_10_GP_NG01/slika.jpg",
        coordinate:{
            lat:45.0492483,
            lng:16.3717171
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:44,
        CameraName:"GP Kostajnica(ulaz)",
        CameraDescription:"Kostajnica(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_11_GP_KO02/slika.jpg",
        coordinate:{
            lat:45.220225,
            lng:16.5448764
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:45,
        CameraName:"GP Kostajnica(izlaz)",
        CameraDescription:"Kostajnica(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_11_GP_KO01/slika.jpg",
        coordinate:{
            lat:45.220225,
            lng:16.5448764
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:46,
        CameraName:"GP Kozarska Dubica(ulaz)",
        CameraDescription:"Kozarska Dubica(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_09_GP_KD02/slika.jpg",
        coordinate:{
            lat:45.1843092,
            lng:16.8066413
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:47,
        CameraName:"GP Kozarska Dubica(izlaz)",
        CameraDescription:"Kozarska Dubica(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_09_GP_KD01/slika.jpg",
        coordinate:{
            lat:45.1843092,
            lng:16.8066413
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:48,
        CameraName:"GP Donja Gradina(ulaz)",
        CameraDescription:"Donja Gradina(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_08_GP_GD02/slika.jpg",
        coordinate:{
            lat:45.2654916,
            lng:16.9166494
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:49,
        CameraName:"GP Donja Gradina(izlaz)",
        CameraDescription:"Donja Gradina(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_08_GP_GD01/slika.jpg",
        coordinate:{
            lat:45.2654916,
            lng:16.9166494
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:50,
        CameraName:"GP Gradiška(ulaz)",
        CameraDescription:"Gradiška(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_01_GP_GR02/slika.jpg",
        coordinate:{
            lat:45.1472435,
            lng:17.2519228
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:51,
        CameraName:"GP Gradiška(izlaz)",
        CameraDescription:"Gradiška(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_01_GP_GR01/slika.jpg",
        coordinate:{
            lat:45.1472435,
            lng:17.2519228
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:52,
        CameraName:"GP Stara Gradiška(izlaz)",
        CameraDescription:"Stara Gradiška(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/59.jpg",
        coordinate:{
            lat:45.149044,
            lng:17.2410239,
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:53,
        CameraName:"GP Stara Gradiška(ulaz)",
        CameraDescription:"Stara Gradiška(ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/60.jpg",
        coordinate:{
            lat:45.149044,
            lng:17.2410239,
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:54,
        CameraName:"GP Brod(ulaz)",
        CameraDescription:"Brod(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_04_GP_BR02/slika.jpg",
        coordinate:{
            lat:45.1516478,
            lng:17.9997574
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:55,
        CameraName:"GP Brod(izlaz)",
        CameraDescription:"Brod(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_04_GP_BR01/slika.jpg",
        coordinate:{
            lat:45.1516478,
            lng:17.9997574
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:56,
        CameraName:"GP Slavonski Brod (izlaz)",
        CameraDescription:"Slavonski Brod(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/196.jpg",
        coordinate:{
            lat:45.1516478,
            lng:17.9997574
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:57,
        CameraName:"GP Slavonski Brod (ulaz)",
        CameraDescription:"Slavonski Brod(ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/195.jpg",
        coordinate:{
            lat:45.1516478,
            lng:17.9997574
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:58,
        CameraName:"GP Županja (izlaz) ",
        CameraDescription:"Županja(ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/80.jpg",
        coordinate:{
            lat:45.0388593,
            lng:18.6940146
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:59,
        CameraName:"GP Županja  (ulaz) ",
        CameraDescription:"Županja(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/79.jpg",
        coordinate:{
            lat:45.0388593,
            lng:18.6940146
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:60,
        CameraName:"GP Orašje (izlaz)",
        CameraDescription:"Orašje",
        CameraImage:"https://www.hak.hr/info/kamere/401.jpg",
        coordinate:{
            lat:45.0313749,
            lng:18.6999106
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:76,
        CameraName:"GP Orašje (ulaz)",
        CameraDescription:"Orašje",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/GPORAŠJE.jpg",
        coordinate:{
            lat:45.0313749,
            lng:18.6999106
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:1

    },
    {
        id:61,
        CameraName:"GP Gunja (izlaz)",
        CameraDescription:"Gunja(izlaz)",
        CameraImage:"https://www.hak.hr/info/kamere/432.jpg",
        coordinate:{
            lat:44.8830546,
            lng:18.810887
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:62,
        CameraName:"GP Gunja (ulaz)",
        CameraDescription:"Gunja(ulaz)",
        CameraImage:"https://www.hak.hr/info/kamere/431.jpg",
        coordinate:{
            lat:44.8830546,
            lng:18.810887
        },
        CameraType:"hakmarker",
        Type:2,
        Source:2,
        Path:1

    },
    {
        id:63,
        CameraName:"GP Brčko (izlaz)",
        CameraDescription:"Granični prijelaz Brčko",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/BRCKO.jpg",
        coordinate:{
            lat:44.8834696,
            lng:18.8091987
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:1

    },
    {
        id:64,
        CameraName:"GP Rača(ulaz)",
        CameraDescription:"Rača(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_02_GP_RA02/slika.jpg",
        coordinate:{
            lat:44.9045652,
            lng:19.3005109
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    },
    {
        id:65,
        CameraName:"GP Rača(izlaz)",
        CameraDescription:"Rača(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_02_GP_RA01/slika.jpg",
        coordinate:{
            lat:44.9045652,
            lng:19.3005109
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    },
    {
        id:66,
        CameraName:"GP Šepak(ulaz)",
        CameraDescription:"Šepak(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_06_GP_SP02/slika.jpg",
        coordinate:{
            lat:44.542815,
            lng:19.1786192
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    },
    {
        id:67,
        CameraName:"GP Šepak(izlaz)",
        CameraDescription:"Šepak(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_06_GP_SP01/slika.jpg",
        coordinate:{
            lat:44.542815,
            lng:19.1786192
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    },
    {
        id:68,
        CameraName:"GP Karakaj(ulaz)",
        CameraDescription:"Karakaj(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_07_GP_KA02/slika.jpg",
        coordinate:{
            lat:44.4064389,
            lng:19.1227984
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    },
    {
        id:69,
        CameraName:"GP Karakaj(izlaz)",
        CameraDescription:"Karakaj(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_07_GP_KA01/slika.jpg",
        coordinate:{
            lat:44.4064389,
            lng:19.1227984
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:2

    }, 
    {
        id:70,
        CameraName:"GP Hum",
        CameraDescription:"Granični prijelaz Hum",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/HUM.jpg",
        coordinate:{
            lat:43.3490365,
            lng:18.8425532
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:3

    },
    {
        id:71,
        CameraName:"GP Deleuša",
        CameraDescription:"Granični prijelaz Deleuša",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/DELEUSA.jpg",
        coordinate:{
            lat:42.862974,
            lng:18.4757906
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:3

    },
    {
        id:72,
        CameraName:"GP Klobuk",
        CameraDescription:"Granični prijelaz Klobuk",
        CameraImage:"https://bihamk.ba/assets/video-surveillance/KLOBUK.jpg",
        coordinate:{
            lat:42.7124342,
            lng:18.5473144
        },
        CameraType:"bihamkmarker",
        Type:2,
        Source:3,
        Path:3

    },
    {
        id:73,
        CameraName:"GP Zupci(ulaz)",
        CameraDescription:"Zupci(ulaz)",
        CameraImage:"http://139.59.212.224/AMSRS_03_GP_TB02/slika.jpg",
        coordinate:{
            lat:42.5699529,
            lng:18.4295543
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:3

    },
    {
        id:74,
        CameraName:"GP Zupci(izlaz)",
        CameraDescription:"Zupci(izlaz)",
        CameraImage:"http://139.59.212.224/AMSRS_03_GP_TB01/slika.jpg",
        coordinate:{
            lat:42.5699529,
            lng:18.4295543
        },
        CameraType:"amsrsmarker",
        Type:2,
        Source:1,
        Path:3

    },
    {
        id:78,
        CameraName:"M-6.1 Mostar - Široki Brijeg",
        CameraDescription:"M-6.1 Mostar - Široki Brijeg",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija18/0Zovnica.jpg",
        coordinate:{
            lat:43.3409339,
            lng:17.7446501
          },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },
    {
        id:79,
        CameraName:"M-17 Žepče - Maglaj",
        CameraDescription:"M-17 Žepče - Maglaj",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija19/0Zepce.jpg",
        coordinate:{
            lat:44.434374,
            lng:18.037113
          },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:0

    },
    {
        id:80,
        CameraName:"GP Orašje izlaz",
        CameraDescription:"GP Orašje izlaz",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija20/0Orasje.jpg",
        coordinate:{
            lat:45.026396,
            lng:18.700605
          },
        CameraType:"home",
        Type:2,
        Source:1,
        Path:1

    },
    {
        id:81,
        CameraName:"M-17.5 Risovac(Blidinje)",
        CameraDescription:"Risovac Blidinje",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija21/0Risovac.jpg",
        coordinate:{
            lat:43.677381,
            lng:17.586263
          },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:1

    },
    {
        id:82,
        CameraName:"M-5 Kiseljak",
        CameraDescription:"Kiseljak",
        CameraImage:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija22/0Kiseljak.jpg",
        coordinate:{
            lat:43.9481927,
            lng:18.0709641
          },
        CameraType:"home",
        Type:1,
        Source:1,
        Path:1

    }
];

export const ImageNames=[
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_1/BorovaGlava.jpg",
        alt:"Borova glava"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_2/KupreskaVrata.jpg",
        alt:"Kupreška vrata"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_3/Jajce.jpg",
        alt:"Jajce"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_4/0Gorica.jpg",
        alt:"GP Gorica"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_5/0Mesihovina.jpg",
        alt:"Mesihovina"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_6/Donji_Malovan.jpg",
        alt:"Donji Malovan"
    },
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/Lokacija_7/Drvar.jpg",
        alt:"Drvar - Podovi"
    },/*
    {
        src:"https://www.amsbih.ba/amsbih.ba/kamere/kamere/kamera_1/ULAZ/ulaz.jpg",
        alt:"Ulaz"
    }*/

]

